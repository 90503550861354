<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <!-- <el-col :span="4"> </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>-->
    </el-row> 
    <div>
      <!-- <el-button class="but" type="primary" @click="showAddProduct"
        >添加违约金规则</el-button
      >-->
    </div> 
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="违约原因" prop="rule"></el-table-column>
      <el-table-column label="扣除金额" prop="money">
        <template #default="scope">
          <span>{{ this.util.formatNum(scope.row.money / 100) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEditProduct(scope.row)"
          >
            编辑
          </span>
          <!-- <span class="option option-primary" @click="lock(1)"> 启用 </span>
          <span class="option option-primary" @click="lock(1)"> 禁用 </span> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitRule"
    ></EditModal>
  </div>
</template>
 
<script>
import EditModal from "./component/EditModal";
import { updatePenaltyRule, fetchPenaltyRuleList } from "@/api/penalty";
export default {
  components: { EditModal },
  data() {
    return {
      dataSource: [],
      query: {
        money: -1,
        ruleName: "",
        rule: "",
        type: -1,
        ruleHour: -1,
        createAt: "",
      },
      modalData: {},
      pages: {
        pageSize: 10,
        pageIndex: 1,
      },
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    showAddProduct() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    showEditProduct(data) {
      this.modalData = this.deepClone(data);
      this.modalData.money = this.util.formatNum(this.modalData.money / 100);
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    // 提交修改规则
    submitRule(data) {
      updatePenaltyRule(data).then((res) => {
        this.$message.success('修改成功');
        this.getList();
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchPenaltyRuleList({ query: this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records || [];
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
